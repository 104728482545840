<template>
  <div>
    <div v-html="content" class="pd_30 detail_content"></div>
  </div>
</template>
<script>
import { getNews } from '@/api/home';
export default {
  props: {
    title: {
      type: String
    },
    id: {
      type: [String, Number]
    }
  },
  data() {
    return {
      content: ''
    };
  },
  watch: {
    id() {
      this.getPageList();
    }
  },
  mounted() {
    if (this.id) {
      this.getPageList();
    }
  },
  methods: {
    getPageList() {
      this.content = '';
      getNews(this.id).then((res) => {
        this.content = res.data.content;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.map {
  height: 515px;
}
::v-deep .el-input-group__prepend {
  width: 60px;
  background-color: #fff;
  padding-right: 0;
}
::v-deep .el-input__inner {
  border-left: 0;
}
::v-deep .el-input-group__append {
  background: #fff;
  padding: 0;
}
.primary-button {
  width: 160px;
  height: 46px;
  background: #4690f7;
  border-radius: 4px;
}
.detail_content {
  ::v-deep {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>